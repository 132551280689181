//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Search from "@/components/Search"
//  import translations from './translations.json';
import translations from "@/assets/translations.json";
export default {
    name: "Cart",
    components: { Search },
    data() {
        return {
            config: [
                { label: "select all", icon: true, class: "w15" },
                { label: "product info", class: "w30" },
                { label: "price", class: "w15" },
                { label: "quantity", class: "w15" },
                { label: "amount", class: "w15" },
                { label: "operate", class: "w15" }
            ],
            checkedAll: true,
            allMoney: 0,
            allNUmber: 0,
            dataList: [],
            invalidList: [],
            response: '',
            currency: window.localStorage.getItem('currency'),
            locale: window.localStorage.getItem('locale') || this.$i18n.locale,
        };
    },
    created() {
        this.getDataList()
    },
    methods: { 
        translateGoodsName(goodsName) {
            if (this.locale === 'EN') {
                return translations[goodsName] || goodsName; // Use translations from JSON
            }
            return goodsName; // Return original name if locale is not 'EN'
        },
        pay() {
            this.$router.push({ name: 'orderConfirm' })
        },
        async getDataList() {
    const res = await this.$http.get('/cart', {
        uniacid: window.localStorage.getItem('uniacid')
    })
    if (res.code !== 0) {
        if (res.data.code === 401) {
            window.location.href = '/#/login'
            return false
        }

        let errorMessage = res.message;

        if (this.locale.toUpperCase() === 'EN') {
            if (errorMessage.includes('請先登錄')) {
                errorMessage = 'Please login first';
            }
            // Add more translations as needed
        }

        return this.$message.error(errorMessage);
    }

    let data = res.data;
    let dataList = [];
    let invalidList = [];
    data.items.data.list.forEach(item => {
        if (item.is_invalid) {
            invalidList.push(item);
        } else {
            dataList.push(item);
        }
    });
    this.dataList = dataList;
    this.invalidList = invalidList;
    this.response = data;

    console.log("this.dataList",this.dataList.length);

},

async editCartGoods(goods) {
    const res = await this.$http.post('/cart/edit', {
        goods_id: goods.goods_id,
        goods_sku_key: goods.goods_sku_key,
        goods_number: goods.goods_number,
        is_checked: goods.is_checked,
        is_direct: 0,
    })
    if (res.code !== 0) {
        let errorMessage = res.message;

        if (this.locale.toUpperCase() === 'EN') {
            if (errorMessage.includes('請先登錄')) {
                errorMessage = 'Please login first';
            }
            // Add more translations as needed
        }

        return this.$message.error(errorMessage);
    }
    this.getDataList();
},

        numberChange(goods, type = 'reduce') {
            this.editCartGoods(goods, type)
        },

        checked(index) {
            this.dataList[index].is_checked = !this.dataList[index].is_checked
            this.editCartGoods(this.dataList[index])
        },

        async toggleAllCheckButton() {
            const res = await this.$http.post('/cart/changeSelectStatus', {
                status: this.response.submit.data.field.is_all_checked ? 0 : 1
            })
            if (res.code !== 0) return this.$message.error(res.message)
            this.getDataList()
        },

        async deleteItem(goods) {
    const res = await this.$http.post('/cart/delete', {
        cart_id: goods.cart_id
    });
    
    if (res.code !== 0) {
        return this.$message.error(res.message);
    }

    // Re-fetch the cart data after deletion
    await this.getDataList();
},

async getDataList() {
    try {
        const cartRes = await this.$http.get('/cart', {
            uniacid: window.localStorage.getItem('uniacid')
        });

        let data = cartRes.data;
        let dataList = [];
        let invalidList = [];

        data.items.data.list.forEach(item => {
            if (item.is_invalid) {
                invalidList.push(item);
            } else {
                dataList.push(item);
            }
        });

        this.dataList = dataList;
        this.invalidList = invalidList;
        this.response = data;

        // Update Vuex store with the new cart length
        this.$store.dispatch('global/updateCartItemsCount', dataList.length);

        // Update cart count in localStorage as well
       // localStorage.setItem('cartItemsCount', dataList.length);
    } catch (error) {
        console.error("Error fetching cart data:", error);
    }
},


        isCheckedAll() {
            this.checkedAll = this.cartList.every(item => item.checked == true)
            this.allMoney = 0
            this.allNUmber = this.cartList.reduce((val, item, index) => {
                item.checked == true && (this.allMoney = this.allMoney + (item.shop_price * item.goods_number))
                item.checked == true && (val += item.goods_number)
                return val
            }, 0)
        },
    }
};
